import get from 'just-safe-get'

/*
 * check if the price book gross price is lower than the payed cost of a product
 * in case cost, price or vat is missing, then we do not calculate it anymore and return false
 */
export const priceBookPriceLowerThanCost = (
  productEmbed,
  pricebookPrice,
  vat
) => {
  const cost = get(productEmbed, 'prices.default_prices.0.cost', 0)
  const price = get(productEmbed, 'prices.default_prices.0.purchase_price', 0)
  if (
    !cost ||
    !price ||
    typeof vat !== 'number' ||
    typeof pricebookPrice !== 'number'
  )
    return false
  return (cost + price) * (1 + vat) > pricebookPrice
}

/*
 * Handle discounted by and marked up by values on change
 */
export function handleNewValues(margin, discountedBy, gross, net, path) {
  const newValues = {
    margin,
    discounted_by: discountedBy,
    markedup_by: null,
    amount_gross: gross,
    amount_net: net
  }

  if (discountedBy > 0) {
    newValues.discounted_by = discountedBy
    newValues.markedup_by = null
  } else if (discountedBy < 0) {
    newValues.discounted_by = null
    newValues.markedup_by = -discountedBy
  } else if (path === 'discounted_by' && discountedBy === 0) {
    newValues.discounted_by = discountedBy
    newValues.markedup_by = null
  } else if (path === 'markedup_by' && discountedBy === 0) {
    newValues.discounted_by = null
    newValues.markedup_by = -discountedBy
  }

  if (path === 'amount_gross' && (gross === 0 || gross === null)) {
    newValues.discounted_by = null
    newValues.markedup_by = null
    newValues.amount_gross = null
    newValues.amount_net = null
    newValues.margin = null
  }

  return newValues
}
