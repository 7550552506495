<template>
  <el-form
    ref="form"
    class="th-table-responsive bg-th-primary-light"
    :model="form"
    :rules="rules"
    data-testid="entry-add-row"
    @submit.prevent="submitForm"
  >
    <table class="th-table">
      <thead>
        <tr>
          <th class="py-0">
            <div :style="{ 'min-width': productColWidth }" />
          </th>
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
          <th class="min-w-field py-0 w-40" />
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <el-form-item
              :label="$t('pages.pricebooks.entries.all.table.name')"
              prop="product"
            >
              <remote-search-select
                :key="rerenderProductsSelect"
                v-model="form.product"
                popper-append-to-body
                resource="products"
                class="w-full"
                fetch-handler="getAll"
                :computed-fields="['custom_id', 'name']"
                :placeholder="
                  $t(
                    'pages.pricebooks.entries.product_search_select.placeholder'
                  )
                "
                :modify-query="modifyQuery"
                :result-filter="filterProducts"
                @resource-set="selectProduct"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="$t('pages.pricebooks.entries.all.table.default_price')"
            >
              <th-currency-input
                :model-value="getDefaultPrice"
                :currency="form.currency"
                disabled
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="$t('pages.pricebooks.entries.all.table.discounted_by')"
            >
              <th-number-input
                ref="calculatedDiscountedBy"
                percent
                submit-on-enter-key
                :precision="2"
                :upper-limit="1.0"
                :locale="$i18n.locale"
                prefix="-"
                :placeholder="'-' + $formatNumber(0, { style: 'percent' })"
                :model-value="form.discounted_by"
                :disabled="!getDefaultPrice"
                positive-only
                @update:modelValue="(v) => handleChange(v, 'discounted_by')"
                @cleared="restorePrice()"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="$t('pages.pricebooks.entries.all.table.markedup_by')"
            >
              <th-number-input
                ref="calculatedMarkedupBy"
                percent
                positive-only
                prefix="+"
                submit-on-enter-key
                :upper-limit="100"
                :placeholder="'+' + $formatNumber(0, { style: 'percent' })"
                :model-value="form.markedup_by"
                :disabled="!getDefaultPrice"
                :locale="$i18n.locale"
                @update:modelValue="(v) => handleChange(v, 'markedup_by')"
                @cleared="restorePrice()"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item
              :label="$t('pages.pricebooks.entries.all.table.margin')"
            >
              <th-number-input
                :model-value="defaultMarginValue"
                percent
                placeholder="–"
                :locale="$i18n.locale"
                :precision="2"
                disabled
              />
            </el-form-item>
          </td>
          <td>
            <el-form-item
              :label="$t('pages.pricebooks.entries.all.table.gross_book_price')"
            >
              <input-message
                :message="$t('pages.pricebooks.entries.all.table.overprice')"
                type="warning"
                :show="showMessage"
              >
                <th-currency-input
                  :currency="form.currency"
                  :disabled="!form.product_embed"
                  :lower-limit="0"
                  :model-value="form.amount_gross"
                  @update:modelValue="(v) => handleChange(v, 'amount_gross')"
                />
              </input-message>
            </el-form-item>
          </td>

          <td class="">
            <el-button
              v-loading="loading"
              data-testid="add-button"
              type="primary"
              class="w-full"
              plain
              native-type="submit"
              v-text="$t('common.interactions.buttons.add')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </el-form>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import deepClone from 'clone-deep'
import * as price from './price.js'
import RemoteSearchSelect from '@/components/select/remote-search'
import InputMessage from '@/components/inputs/message'
import { getDefaultCurrency } from '@/utils/general'
import { productType, isPurchaseItem } from '@/utils/products'
import { priceBookPriceLowerThanCost, handleNewValues } from './helper'

const getDefaultForm = () => ({
  product: null,
  product_embed: null,
  margin: null,
  discounted_by: null,
  markedup_by: null,
  amount_gross: null,
  currency: getDefaultCurrency()
})

export default {
  components: {
    RemoteSearchSelect,
    InputMessage
  },
  props: {
    productsIdArray: {
      type: Array,
      default: () => []
    },
    taxes: {
      type: Array,
      default: () => []
    },
    availableIn: {
      type: Object,
      default: () => ({})
    },
    productColWidth: {
      type: String,
      default: ''
    },
    pricebookId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      isSubmitting: false,
      form: getDefaultForm(),
      rules: {
        product: [
          {
            validator: (rule, value, callback) => {
              if (!this.isSubmitting) return callback()

              if (!value) {
                callback(
                  new Error(
                    this.$t('common.forms.rules.field_warnings.required')
                  )
                )
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },

  computed: {
    showMessage() {
      const vat = this.taxes?.find((tax) => tax.id === this.form.tax)?.rate
      return priceBookPriceLowerThanCost(
        this.form.product_embed,
        this.form.amount_gross,
        vat
      )
    },

    getDefaultPrice() {
      return get(
        this.form.product_embed,
        'prices.default_prices.0.amount.gross',
        0
      )
    },

    taxRate() {
      const tax = this.taxes.find((tax) => tax.id === this.form.tax) || {}
      return tax.rate || 0
    },

    initialMargin() {
      return get(this.form, 'product_embed.prices.default_prices.0.margin', 0)
    },

    defaultMarginValue() {
      return this.form.margin || this.initialMargin
    },

    rerenderProductsSelect() {
      // We want to trigger the search again for the new location that was selected
      // We add the change of new products in the entries to trigger the filtering
      return JSON.stringify(this.availableIn) + this.productsIdArray.length
    }
  },
  methods: {
    async submitForm() {
      // since it's a form inside a form, we want to validate the entry form only before submitting, and not while interacting with the fields.
      this.isSubmitting = true
      const valid = await this.validate()
      this.isSubmitting = false
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      const form = deepClone(this.form)

      const { discountedBy, gross, net } = this.getPricesData(
        form?.product_embed?.prices || {},
        form,
        this.taxRate
      )

      const entry = {
        amount_gross: gross,
        amount_net: net,
        currency: form.currency,
        discounted_by: discountedBy,
        price_book: this.pricebookId,
        product: form.product,
        product_groups: null,
        products: null,
        rate: null,
        tax: form.tax
      }

      // If we have a pricebook, then we create the entries directly
      // Otherwise they will be created after the pricebook creation
      if (this.pricebookId) {
        this.create(entry)
      } else {
        // Adding the necessary information for the entry
        entry.product_embed = form.product_embed
        this.$emit('entry', entry)
        this.reset()
      }
    },

    getPricesData(prices = {}, discounts, tax) {
      if (discounts.discounted_by) {
        return price.onDiscountedBy(discounts.discounted_by, prices, tax)
      }
      if (discounts.amount_gross) {
        return price.onGross(discounts.amount_gross, prices, tax)
      }
      return price.onGross(
        prices?.default_prices[0]?.amount?.gross,
        prices,
        tax
      )
    },

    async validate() {
      return new Promise((resolve, _) => {
        this.$refs.form.validate(resolve)
      })
    },

    async create(entry) {
      try {
        this.loading = true
        await th.products().pricebookEntries().create(entry)
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t('common.resource.pricebook_entry.singular')
          })
        })
        this.reset()
        this.$emit('refresh')
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.pricebook_entry.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    reset() {
      this.$refs.form.resetFields()
      this.form = getDefaultForm()
    },

    modifyQuery(q) {
      const { locations, branch_groups } = this.availableIn
      return {
        query: {
          limit: 50,
          deleted: false,
          sellable: true,
          exclude_system_products: true,
          type: [
            'product',
            'composed_product',
            'voucher',
            'linked',
            'linked_product',
            'variant',
            'variant_product'
          ],
          q: q || undefined,
          location: locations || undefined,
          branch_groups: branch_groups || undefined,
          location_strict:
            locations?.length || branch_groups?.length ? true : undefined
        }
      }
    },

    filterProducts(products) {
      return products.filter(
        (product) =>
          product.type !== productType.VARIANT_PRODUCT &&
          !isPurchaseItem(product) &&
          !this.productsIdArray.includes(product.id)
      )
    },

    restorePrice() {
      const { margin, discountedBy, gross, net } = price.onDiscountedBy(
        0,
        this.form.product_embed.prices,
        this.taxRate
      )
      this.setNewValues(margin, discountedBy, gross, net, 'amount_gross')
    },

    selectProduct(product) {
      this.handleChange(null)
      this.form.product_embed = product
      this.form.tax = this.form.product_embed
        ? this.form.product_embed.tax
        : null
    },

    setNewValues(margin, discountedBy, gross, net, path) {
      const newValues = handleNewValues(margin, discountedBy, gross, net, path)
      this.form = { ...this.form, ...newValues }
    },

    handleChange(value, path) {
      if (!this.form.product_embed) return

      if (value === null) {
        return this.setNewValues(null, null, null, null)
      }

      if (path === 'discounted_by') {
        const { margin, discountedBy, gross, net } = price.onDiscountedBy(
          value,
          this.form.product_embed.prices,
          this.taxRate
        )
        this.setNewValues(margin, discountedBy, gross, net, 'discounted_by')
      } else if (path === 'markedup_by') {
        const { margin, discountedBy, gross, net } = price.onDiscountedBy(
          -value,
          this.form.product_embed.prices,
          this.taxRate
        )
        this.setNewValues(margin, discountedBy, gross, net, 'markedup_by')
      } else if (path === 'amount_gross') {
        const { gross, discountedBy, margin, net } = price.onGross(
          value,
          this.form.product_embed.prices,
          this.taxRate
        )
        this.setNewValues(margin, discountedBy, gross, net, 'amount_gross')
      }
    }
  }
}
</script>

<style scoped>
.th-table td {
  padding-top: 0;
}
</style>
