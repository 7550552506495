<template>
  <th-page-wrapper
    color="var(--font-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'pricebooks-list' })"
  >
    <!-- Form -->
    <pricebooks-form
      ref="form"
      :pricebook-id="pricebookId"
      @set-local-pricebook-id="localPricebookId = $event"
    />
  </th-page-wrapper>
</template>

<script>
import PricebooksForm from './components/form.vue'

export default {
  name: 'PricebooksEdit',

  components: {
    PricebooksForm
  },

  beforeRouteLeave(to, from, next) {
    this.checkLeave((_, canLeave) => {
      if (canLeave) return next()
      return next(false)
    })
  },

  data() {
    return {
      localPricebookId: null
    }
  },

  computed: {
    pricebookId() {
      return this.localPricebookId || this.$route.params.id
    },

    isNew() {
      return !this.pricebookId
    },

    isDirty() {
      return this.$refs.form.isDirty
    },

    actions() {
      return {
        permissionPrefix: 'products:pricebooks',
        returnedPath: { name: 'pricebooks-list' },
        isNew: this.isNew
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },

    handleDelete() {
      this.$refs.form.handleDelete()
    },

    checkLeave(cb) {
      if (!this.isDirty) return cb(null, true)
      this.$confirm(
        this.$t('pages.settings.component.warnings.unsaved.message'),
        this.$t('pages.settings.component.warnings.unsaved.title'),
        {
          confirmButtonText: this.$t('common.interactions.buttons.ok'),
          cancelButtonText: this.$t('common.interactions.buttons.cancel'),
          type: 'warning'
        }
      )
        .then(() => cb(null, true))
        .catch((err) => cb(err, false))
    }
  }
}
</script>
