<template>
  <th-wrapper
    :title="$t('pages.pricebooks.form.sections.entries.title')"
    :info="$t('pages.pricebooks.form.sections.entries.help')"
    body-class="p-0"
    header-class="bg-th-primary-light pb-4"
  >
    <div class="bg-th-primary-light flex items-center py-4 pl-8">
      <!-- Bulk add -->
      <el-button
        plain
        type="primary"
        @click="handleBulkAdd"
        v-text="$t('pages.pricebooks.entries.all.table.buttons.add_entry_bulk')"
      />
      <div class="ml-2">
        {{
          $t('pages.pricebooks.entries.all.table.buttons.add_entry_bulk_lable')
        }}
      </div>
    </div>

    <!-- Additional header -->
    <entry-add-row
      :products-id-array="productIds"
      :taxes="resources.taxes"
      :available-in="availableIn"
      :product-col-width="productColWidth"
      :pricebook-id="pricebookId"
      @refresh="refresh"
      @entry="addLocalEntry"
    />

    <!-- Table -->
    <div class="th-table-responsive shadow-th-light">
      <table class="th-table" data-testid="table-entries">
        <thead>
          <tr>
            <th class="min-w-field py-2 h-12">
              <div
                class="flex h-full w-full items-center"
                :style="{ 'min-width': productColWidth }"
              >
                <!-- Product name -->
                <span>{{ $t('pages.pricebooks.entries.all.table.name') }}</span>
                <!-- Search Entries -->
                <div class="h-full ml-6">
                  <search-input
                    v-if="!isNew"
                    class="search-in-table"
                    :placeholder="
                      $t('pages.pricebooks.form.sections.entries.search')
                    "
                    @submit="fetch"
                  />
                </div>
              </div>
            </th>
            <th class="min-w-field py-2 h-12 w-40">
              <!-- Original Price -->
              {{ $t('pages.pricebooks.entries.all.table.default_price') }}
            </th>
            <th class="min-w-field py-2 h-12 w-40">
              <!-- Discount -->
              {{ $t('pages.pricebooks.entries.all.table.discounted_by') }}
            </th>
            <th class="min-w-field py-2 h-12 w-40">
              <!-- Markup -->
              {{ $t('pages.pricebooks.entries.all.table.markedup_by') }}
            </th>
            <th class="min-w-field py-2 h-12 w-40">
              <!-- Book Margin -->
              {{ $t('pages.pricebooks.entries.all.table.margin') }}
            </th>
            <th class="min-w-field py-2 h-12 w-40">
              <!-- Book Price -->
              {{ $t('pages.pricebooks.entries.all.table.gross_book_price') }}
            </th>
            <th class="py-2 h-12 w-0" />
          </tr>
        </thead>

        <tbody :key="renderKey">
          <entry-row
            v-for="entry in childrenPage"
            :key="entry.id"
            :model-value="entry"
            :taxes="resources.taxes"
            :product-col-width="productColWidth"
          />
        </tbody>
      </table>

      <!-- Table loading -->
      <loading v-if="loading" class="border-b" />

      <!-- Table no data -->
      <div
        v-if="!entries || entries.length === 0"
        class="py-5 px-8 text-gray-500"
      >
        {{ $t('common.data.no_data_yet') }}
      </div>

      <!-- Pagination -->
      <th-pagination
        v-if="entries && entries.length > 0"
        class="bg-th-primary-light"
        :page-size="pageSizes.inline"
        :total="uncheckedEntries.length"
        :current-page="page"
        @current-change="(v) => (page = v)"
        @size-change="changePageSize($event)"
      />
    </div>

    <!-- Bulk entry modal -->
    <bulk-entry-form
      :available-in="availableIn"
      :resources="resources"
      :pricebook-id="pricebookId"
      :products-id-array="productIds"
      @refresh="refresh"
    />
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import pAll from 'p-all'
import EntryRow from './entries/entry-row.vue'
import EntryAddRow from './entries/entry-add-row.vue'
import BulkEntryForm from './entries/bulk-entry-form'
import SearchInput from '@/components/filter-header/search'
import Loading from '@/components/loading'
import { mapGetters } from 'vuex'
import { useMessagesStore } from '@/store/messages'

export default {
  components: {
    EntryRow,
    EntryAddRow,
    BulkEntryForm,
    SearchInput,
    Loading
  },

  props: {
    availableIn: {
      type: Object,
      default: () => ({})
    },
    pricebookId: {
      type: String,
      default: null
    },
    submitFormFunction: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      renderKey: 0,
      page: 1,
      entries: [],
      loading: false,
      headers: [],
      resources: {},
      productColWidth: '15rem'
    }
  },

  computed: {
    ...mapGetters({
      pageSizes: 'Config/getPageSizes',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),

    uncheckedEntries() {
      return this.entries.filter((entry) => !entry.checked)
    },

    childrenPage() {
      return this.uncheckedEntries.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    },

    isNew() {
      return !this.pricebookId
    },

    productIds() {
      return this.entries.map((e) => e.product)
    },

    selectedItems() {
      return this.entries.filter((e) => e.checked)
    },

    dirtyRows() {
      return this.entries.filter((e) => e.dirty)
    }
  },
  watch: {
    childrenPage() {
      // Update the page in case of deleting all the entries of a page
      if (!this.childrenPage.length && this.page !== 1) {
        this.page = this.page - 1
      }
    }
  },

  async mounted() {
    await this.fetchResources()
    if (!this.isNew) this.fetch()
  },

  methods: {
    genBasicEntry() {
      return {
        product: null,
        products: null,
        product_groups: null,
        price_book: null,
        currency: this.defaultCurrency,
        discounted_by: null,
        amount_gross: null,
        amount_net: null,
        rate: null,
        tax: null
      }
    },

    async fetchResources() {
      this.resources = await this.$resourceFetch('taxes', 'productGroups')
    },

    async fetch(query) {
      this.loading = true
      try {
        const options = {
          deleted: false,
          embed: 'product',
          price_book: this.pricebookId
        }
        const searchQuery = query?.q?.value
        if (searchQuery) options.q = searchQuery

        const { data } = await th.products().pricebookEntries().getAll(options)
        data.forEach((e) => {
          // As we have just discounted_by as a field, we need to emulate the markedup_by
          if (e.discounted_by > 0) {
            e.markedup_by = null
          } else if (e.discounted_by < 0) {
            e.markedup_by = -e.discounted_by
            e.discounted_by = null
          }
          e.checked = false
          e.dirty = false
        })
        this.entries = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },

    refresh() {
      this.fetch()
    },

    addLocalEntry(entry) {
      this.entries.push(entry)
    },

    async alter(entry) {
      try {
        await th
          .products()
          .pricebookEntries()
          .put(entry.id, pick(entry, Object.keys(this.genBasicEntry())))
        this.refresh()
      } catch (err) {
        this.$logException(err)
      }
    },

    async handleSave() {
      await this.handleDelete()
      if (this.dirtyRows.length === 0) return
      this.loading = true
      try {
        await this.saveEntries()
        // After save we remount entries rows to reset the pristine form - so it won't keep old state
        this.renderKey++
        this.refresh()
        this.$message({
          type: 'success',
          message: this.$t(
            'pages.pricebooks.entries.messages.bulk_save_success'
          )
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('pages.pricebooks.entries.messages.bulk_save_fail')
        })
      } finally {
        this.loading = false
      }
    },

    async saveEntries() {
      const fields = Object.keys(this.genBasicEntry())
      const actions = this.dirtyRows.map((entry) => {
        return () =>
          th.products().pricebookEntries().put(entry.id, pick(entry, fields))
      })

      await pAll(actions)
    },

    async handleDelete() {
      if (!this.selectedItems.length) return
      // In case is new, we just need to take them out of entries
      if (this.isNew) {
        this.handleLocalDelete()
        return
      }
      const payload = this.selectedItems
      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.delete_confirm.object_names.pricebook_entries'),
        'product_embed.name'
      )
      if (confirm) this.delete(payload)
    },

    uncheckEntries() {
      this.entries = this.entries.map((e) => {
        e.checked = false
        return e
      })
    },

    handleLocalDelete() {
      const selectedProducts = this.selectedItems.map((item) => item.product)
      this.entries = this.entries.filter((entry) => {
        return !selectedProducts.includes(entry.product)
      })
    },

    async delete(payload) {
      if (payload.length > 1) {
        this.handleBulkDelete(payload)
        return
      }

      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.pricebook.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.pricebook.singular')
      })

      try {
        this.loading = true
        await th.products().pricebookEntries().delete(payload[0].id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    async handleBulkAdd() {
      if (this.isNew) {
        const errors = await this.submitFormFunction({
          preventNavigation: true
        })
        if (errors) return
      }
      this.$thModal.show('bulk-entry-form-2')
    },

    handleBulkDelete(payload) {
      const inst = th.products().pricebookEntries()

      const operations = payload.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.success.action.delete.multiple', {
        resources: this.$t('common.resource.pricebook.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.$message({
            type: 'success',
            message: this.$t(
              'pages.pricebooks.entries.messages.bulk_delete_success'
            )
          })
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    changePageSize(value) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageSizes.inline',
        value
      })
    }
  }
}
</script>

<style scoped>
.search-in-table :deep(.el-input__prefix) {
  left: 3px;
}

.search-in-table :deep(.el-input__inner) {
  padding-left: 25px !important;
  font-size: 12px;
  line-height: 1.2;
}

.search-in-table :deep(.el-input__prefix svg) {
  height: 18px !important;
  width: 18px !important;
  fill: currentColor;
}
</style>
