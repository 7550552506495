<template>
  <section class="p-8">
    <!-- General -->
    <pricebooks-general
      ref="general"
      v-model="form"
      data-testid="pricebook-general"
      :resources="resources"
      :is-new="isNew"
    />

    <!-- Validity -->
    <pricebooks-validity
      ref="validity"
      v-model="form.constraints"
      data-testid="pricebook-validity"
    />

    <!-- Entries -->
    <pricebooks-entries
      ref="entries"
      data-testid="pricebook-entries"
      :submit-form-function="submitForm"
      :pricebook-id="pricebookId"
      :available-in="{
        locations: form.locations,
        branch_groups: form.branch_groups
      }"
    />
  </section>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import pAll from 'p-all'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import { getConstraints } from '@/utils/constraints'
import PricebooksValidity from '@/containers/validity-form-section.vue'
import PricebooksGeneral from './pricebooks-general.vue'
import PricebooksEntries from './pricebooks-entries.vue'
import { mapGetters } from 'vuex'
import { useMessagesStore } from '@/store/messages'
import { ERROR_CODE } from '@/constants/errors'

function generateDefault() {
  return {
    name: null,
    locations: null,
    branch_groups: null,
    type: 'general',
    constraints: getConstraints()
  }
}

export default {
  name: 'PricebooksForm',

  components: {
    PricebooksGeneral,
    PricebooksValidity,
    PricebooksEntries
  },

  props: {
    pricebookId: {
      type: String,
      default: null
    }
  },

  data() {
    const form = generateDefault()
    return {
      loading: false,
      form,
      resources: {}
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),

    isDirty() {
      //if pricebooks entries are marked for deletion,
      //route change should show a warning message
      return this.$refs.entries.entries.some(({ checked }) => checked)
    },

    newEntries() {
      return this.$refs.entries.entries
    },

    isNew() {
      return !this.pricebookId
    }
  },

  watch: {
    currentLocation: 'setCurrentLocation'
  },

  async created() {
    if (!this.isNew) this.fetch(this.pricebookId)
    this.fetchResources()
  },

  methods: {
    async fetch(id) {
      this.loading = true
      try {
        const { data = {} } = await th.products().pricebooks().get(id)
        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.pricebooks.edit.form.errors.fetch.code_XXX.content'
          )
        })
      } finally {
        this.loading = false
      }
    },

    async submitForm(createOptions = {}) {
      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }
      this.isNew ? this.create(createOptions) : this.alter()
    },

    async validate() {
      const generalValid = await this.$refs.general.validate()
      const validityValid = await this.$refs.validity.validate()
      return generalValid && validityValid
    },

    async createNewEntries(pricebookId, pricebookName) {
      const actions = this.newEntries.map((entry) => {
        return () =>
          th
            .products()
            .pricebookEntries()
            .create(this.makeHandableEntry(entry, pricebookId, pricebookName))
      })

      await pAll(actions)
    },

    makeHandableEntry(entry, pricebookId, pricebookName) {
      let { product_embed, dirty, ...newEntry } = entry
      newEntry = Object.assign(newEntry, {
        price_book: pricebookId,
        name: pricebookName
      })
      // As we just support discounted_by, we need to clean the markedup_by
      if (newEntry.markedup_by) {
        newEntry.discounted_by = -newEntry.markedup_by
      }
      delete newEntry.markedup_by
      // Clean as well the margin
      delete newEntry.margin
      return newEntry
    },

    async create({ preventNavigation = false } = {}) {
      this.loading = true
      try {
        const payload = this.makeHandleableBody(this.form)
        const {
          data = {},
          errors = []
        } = await th.products().pricebooks().create(payload)

        if (data.id) {
          if (this.newEntries.length) {
            await this.createNewEntries(data.id)
          }
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.pricebook.singular')
            })
          })

          this.handleItem(data)
          if (preventNavigation) {
            //to prevent navigation after creation (e.g. when creating a pricebook with bulk add)
            return
          } else if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'pricebook-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({
              name: 'pricebooks-list'
            })
          }
        }

        if (errors.length) {
          this.$log.debug(
            'pricebooks-create: errors on success',
            JSON.stringify(errors, null, 2)
          )
          errors.forEach((errorObj) => {
            useMessagesStore().setLocalMessage({
              id: errorObj.id,
              label: errorObj.label,
              operation: 'local_message',
              payload: errorObj.errorDetails
            })
          })
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.pricebook.singular')
          })
        })
      } finally {
        this.loading = false
        this.$ampli.eventWithBaseProps('priceBookCreated')
      }
    },

    async alter() {
      this.loading = true
      try {
        await this.$refs.entries.handleSave()
        const payload = this.makeHandleableBody(this.form)
        const { data = {} } = await th
          .products()
          .pricebooks()
          .put(this.pricebookId, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.pricebook.singular')
            })
          })
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.pricebook.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.deletePricebook()
    },

    async deletePricebook() {
      this.loading = true
      try {
        await th.products().pricebooks().delete(this.payload.id)
        this.$router.push({ name: 'pricebooks-list' })
      } catch (err) {
        const { error_code } = err?.properties?.error?.response?.data
        const message =
          error_code === ERROR_CODE.PRICEBOOK_IN_USE
            ? this.$t('pages.pricebooks.edit.form.errors.pricebook_in_use')
            : this.$t('common.error.action.delete.single', {
                resource: this.$t('common.resource.pricebook.singular')
              })

        this.$logException(err, { message })
      } finally {
        this.loading = false
      }
    },

    makeHandleableBody(form) {
      // replace empty objects, empty arrays and empty strings with null
      return Object.keys(form).reduce((result, key) => {
        const isEmpty =
          isEmptyObject(form[key]) ||
          isEmptyArray(form[key]) ||
          isEmptyString(form[key])
        result[key] =
          isEmpty && !['branch_groups', 'locations'].includes(key)
            ? null
            : form[key]
        return result
      }, {})
    },

    handleItem(item) {
      this.payload = item
      const cleanedPayload = this.$deepClone(pick(item, Object.keys(this.form)))

      this.form = {
        ...this.form,
        ...cleanedPayload
      }
      this.$emit('set-local-pricebook-id', item.id)
    },

    setCurrentLocation() {
      if (this.isNew && !this.form.locations) {
        this.form.locations = this.currentLocation
          ? [this.currentLocation]
          : null
      }
    },

    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups')
        this.resources = { branches: branchesV1, branchGroups }
        this.setCurrentLocation()
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.products.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    }
  }
}
</script>
