<template>
  <div>
    <select-holder
      :loading="loading"
      :placeholder="$t('components.multi_items_select.select.placeholder')"
      :shown-items="shownItems"
      @show-modal="$thModal.show('product-groups-select')"
    />

    <th-modal
      name="product-groups-select"
      height="800px"
      width="1200px"
      :title="$t('components.multi_items_select.product_groups.select.title')"
    >
      <group-items-select
        v-model="selectedItems"
        resource="productGroups"
        show-search-filter
        fuzzy-search
        :filters="filtersList"
        :normalise-keys="normalizeKeys"
        full-selected-object
      />

      <template #footer>
        <actions @cancel="handleCancel" @save="handleSave" />
      </template>
    </th-modal>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pAll from 'p-all'
import compare from 'just-compare'
import GroupItemsSelect from '@/components/group-items-select'
import SelectHolder from './components/select-holder'
import Actions from './components/actions'

export default {
  components: {
    GroupItemsSelect,
    SelectHolder,
    Actions
  },
  props: {
    modelValue: {
      type: [Array, null],
      required: false,
      default: () => []
    },
    resources: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedItems: [],
      shownItems: [],
      loading: false,
      filtersList: [
        {
          name: 'name',
          type: 'input',
          label: this.$t('pages.product_groups.all.headers.name')
        },
        {
          name: 'product_group_id',
          type: 'input',
          label: this.$t('pages.product_groups.all.headers.product_group_id')
        }
      ]
    }
  },
  computed: {
    normalizeKeys() {
      return (productGroup) => ({
        ...productGroup,
        title_main: productGroup.name,
        title_secondary: productGroup.product_group_id
      })
    }
  },
  watch: {
    modelValue(newItems, oldItems) {
      if (Array.isArray(newItems) && !compare(newItems, oldItems)) {
        this.selectedItems = this.modelValue
        this.fetchSelectedItemsData()
      }
    }
  },
  methods: {
    handleCancel() {
      this.selectedItems = this.modelValue || []
      this.$thModal.hide('product-groups-select')
    },
    handleSave() {
      const products = this.selectedItems.length
        ? this.selectedItems
        : undefined
      this.$emit('update:modelValue', products)
      this.$thModal.hide('product-groups-select')
      this.fillShownItems(products)
    },
    fillShownItems(items = []) {
      this.shownItems = [...items]
    },
    async fetchSelectedItemsData() {
      if (
        Array.isArray(this.modelValue) &&
        this.modelValue.length &&
        typeof this.modelValue[0] === 'string'
      ) {
        try {
          this.loading = true
          const inst = th.productGroups()
          const actions = this.modelValue.map((item) => () => inst.get(item))
          const res = await pAll(actions)
          const itemsData = res.map((item) => item.data)
          this.selectedItems = itemsData
          this.$emit('update:modelValue', itemsData)
          this.fillShownItems(itemsData)
        } catch (err) {
          this.$logException(err, { trackError: false })
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
