<template>
  <tr
    :class="[
      'entry-row border-b align-middle',
      { dirty: entry.dirty, 'bg-gray-100': entry.checked }
    ]"
  >
    <!-- Product name -->
    <td
      ref="productTextCell"
      :class="{ 'text-gray-500': entry.checked }"
      class="overflow-hidden"
      :style="{ 'max-width': productColWidth, 'text-overflow': 'ellipsis' }"
    >
      <el-tooltip
        effect="dark"
        placement="top"
        :content="productText"
        :disabled="!shouldShowTooltip"
      >
        <span ref="productText">
          {{ productText }}
        </span>
      </el-tooltip>
    </td>

    <!-- Product default price -->
    <td :class="['text-right', { 'text-gray-500': entry.checked }]">
      <th-currency-input
        :currency="entry.currency"
        :model-value="getDefaultPrice"
        placeholder="–"
        disabled
      />
    </td>

    <!-- Discount -->
    <td>
      <th-number-input
        ref="calculatedDiscountedBy"
        :class="{
          flashing: flashing.discounted_by === true
        }"
        :locale="$i18n.locale"
        percent
        prefix="-"
        positive-only
        submit-on-enter-key
        :upper-limit="1.0"
        :placeholder="'-' + $formatNumber(0, { style: 'percent' })"
        :model-value="entry.discounted_by"
        :disabled="!getDefaultPrice || entry.checked"
        @update:modelValue="(v) => handleChange(v, 'discounted_by')"
        @cleared="restorePrice()"
        @submit="$emit('submit')"
      />
    </td>

    <!-- Markup -->
    <td>
      <th-number-input
        ref="calculatedMarkedupBy"
        :class="{
          flashing: flashing.markedup_by === true
        }"
        percent
        positive-only
        prefix="+"
        submit-on-enter-key
        :upper-limit="100"
        :placeholder="'+' + $formatNumber(0, { style: 'percent' })"
        :model-value="entry.markedup_by"
        :disabled="!getDefaultPrice || entry.checked"
        :locale="$i18n.locale"
        @update:modelValue="(v) => handleChange(v, 'markedup_by')"
        @cleared="restorePrice()"
        @submit="$emit('submit')"
      />
    </td>

    <!-- Price book margin -->
    <td>
      <th-number-input
        :model-value="margin"
        :class="{ flashing: flashing.margin === true }"
        :locale="$i18n.locale"
        percent
        placeholder="–"
        disabled
      />
    </td>

    <!-- Book price -->
    <td>
      <input-message
        :message="$t('pages.pricebooks.entries.all.table.overprice')"
        type="warning"
        css-classes="mt-4"
        :show="showMessage"
      >
        <th-currency-input
          submit-on-enter-key
          :class="{
            flashing: flashing.amount_gross === true
          }"
          :currency="entry.currency"
          :disabled="entry.checked"
          :locale="$i18n.locale"
          :lower-limit="0"
          :model-value="entry.amount_gross"
          @update:modelValue="(v) => handleChange(v, 'amount_gross')"
          @submit="$emit('submit')"
        />
      </input-message>
    </td>
    <td class="text-right">
      <!-- Delete -->
      <el-button
        v-permissions="{ scopes: ['products:pricebooks:delete'] }"
        icon="Delete"
        class="el-button--text-icon"
        @click="entry.checked = !entry.checked"
      />
    </td>
  </tr>
</template>

<script>
import get from 'just-safe-get'
import pick from 'just-pick'
import compare from 'just-compare'
import typeOf from 'just-typeof'
import deepClone from 'clone-deep'
import * as price from './price.js'
import { priceBookPriceLowerThanCost, handleNewValues } from './helper'
import InputMessage from '@/components/inputs/message'

export default {
  components: {
    InputMessage
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    taxes: {
      type: Array,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    productColWidth: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      shouldShowTooltip: false,
      pristineForm: null,
      flashing: {
        margin: false,
        discounted_by: false,
        markedup_by: false,
        amount_gross: false
      }
    }
  },

  computed: {
    entry: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    showMessage() {
      const vat = this.taxes?.find((tax) => tax.id === this.entry.tax)?.rate
      return priceBookPriceLowerThanCost(
        this.entry.product_embed,
        this.entry.amount_gross,
        vat
      )
    },

    getCost() {
      return get(this.entry.product_embed, 'prices.default_prices.0.cost', 0)
    },

    getPurchasePrice() {
      return get(
        this.entry.product_embed,
        'prices.default_prices.0.purchase_price',
        0
      )
    },

    margin() {
      const { margin } = price.onGross(
        this.entry.amount_gross,
        this.entry.product_embed.prices,
        this.taxRate
      )
      return margin
    },

    taxRate() {
      const tax = this.taxes.find((tax) => tax.id === this.entry.tax) || {}
      return tax.rate || 0
    },

    getDefaultPrice() {
      return get(
        this.entry,
        'product_embed.prices.default_prices.0.amount.gross',
        0
      )
    },
    productText() {
      const { custom_id, name } = this.entry.product_embed
      const productParts = [custom_id, name].filter(Boolean)
      if (!productParts.length) return '--'
      return productParts.join(' - ')
    }
  },

  mounted() {
    this.pristineForm = deepClone(this.entry)
    this.setInitialPriceValues()
    this.$nextTick(() => {
      this.shouldShowTooltip = this.isTextBiggerThanCell()
    })
  },

  methods: {
    restorePrice() {
      const { margin, discountedBy, gross, net } = price.onDiscountedBy(
        0,
        this.entry.product_embed.prices,
        this.taxRate
      )
      this.setNewValues(margin, discountedBy, gross, net, 'amount_gross')
    },

    handleChange(value, path) {
      if (value === null) {
        return this.setNewValues(null, null, null, null)
      }

      if (path === 'discounted_by') {
        const { margin, discountedBy, gross, net } = price.onDiscountedBy(
          value,
          this.entry.product_embed.prices,
          this.taxRate
        )
        this.setNewValues(margin, discountedBy, gross, net, 'discounted_by')
      } else if (path === 'markedup_by') {
        const { margin, discountedBy, gross, net } = price.onDiscountedBy(
          -value,
          this.entry.product_embed.prices,
          this.taxRate
        )
        this.setNewValues(margin, discountedBy, gross, net, 'markedup_by')
      } else if (path === 'amount_gross') {
        const { gross, discountedBy, margin, net } = price.onGross(
          value,
          this.entry.product_embed.prices,
          this.taxRate
        )
        this.setNewValues(margin, discountedBy, gross, net, 'amount_gross')
      }
    },

    setNewValues(margin, discountedBy, gross, net, path) {
      const newValues = handleNewValues(margin, discountedBy, gross, net, path)
      this.entry.discounted_by = newValues.discounted_by
      this.entry.markedup_by = newValues.markedup_by
      this.entry.amount_gross = newValues.amount_gross
      this.entry.amount_net = newValues.amount_net
      this.entry.margin = newValues.margin
      this.setDirty()

      const marginFlash = this.entry.margin ? 'margin' : undefined
      this.flashFields([marginFlash, path].filter(Boolean))
      this.$emit('update:modelValue', this.entry)
    },

    setInitialPriceValues() {
      this.pristineForm = deepClone(this.entry)

      // Check if the discounted_by is a discount or markup
      if (get(this.entry, 'discounted_by') < 0) {
        this.entry.markedup_by = -this.entry.discounted_by
        this.entry.discounted_by = null
      }
    },

    flashFields(fields) {
      if (!fields || !fields.length) return
      fields.forEach((item) => {
        this.flashing[item] = true
        setTimeout(() => {
          this.flashing[item] = false
        }, 900)
      })
    },

    reset() {
      this.$refs.form.resetFields()
    },

    validate(cb) {
      this.$refs.form.validate((valid) => {
        return cb(valid)
      })
    },

    isTextBiggerThanCell() {
      const cellWidth = this.$refs.productTextCell?.offsetWidth
      const textWidth = this.$refs.productText?.offsetWidth
      if (typeOf(cellWidth) !== 'number' || typeOf(textWidth) !== 'number') {
        return true
      }
      return cellWidth < textWidth
    },

    setDirty() {
      const fields = ['amount_gross', 'discounted_by', 'amount_net']
      const isDirty = !compare(
        pick(this.entry, fields),
        pick(this.pristineForm, fields)
      )
      this.entry.dirty = isDirty
    }
  }
}
</script>

<style scoped>
.dirty {
  border-left: 3px solid var(--info-color);
}

.entry-row :deep(.flashing) {
  transition: color 0.3s;
}

.entry-row :deep(.flashing input) {
  color: var(--info-color) !important;
}
</style>
