<template>
  <th-modal
    name="bulk-entry-form-2"
    width="800px"
    :title="$t(`pages.pricebooks.entries.bulk.create.title`)"
    data-testid="bulk-entry-form"
  >
    <el-form ref="form" :model="bulk" :rules="rules">
      <el-row :gutter="20">
        <!-- Products -->
        <el-col :lg="12">
          <el-form-item
            :label="$t('pages.pricebooks.entries.bulk.create.products')"
            prop="products"
          >
            <products-select
              v-model="bulk.products"
              data-testid="products-select"
              class="w-full"
              :resources="resources"
              :extend-query="extendQuery"
              :result-filter="filterProducts"
              :extend-filters="extendFilters"
            />
          </el-form-item>
        </el-col>

        <!-- Product Groups -->
        <el-col :lg="12">
          <el-form-item
            :label="$t('pages.pricebooks.entries.bulk.create.product_groups')"
            prop="product_groups"
          >
            <product-groups-select
              v-model="bulk.product_groups"
              :resources="resources"
              class="w-full"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Type -->
        <el-col :lg="12">
          <el-form-item
            :label="$t('pages.pricebooks.entries.create.type')"
            prop="type"
            class="xl:mb-0 lg:mb-0"
          >
            <el-select
              id="type"
              v-model="selectedType"
              class="w-full"
              @change="resetTypes"
            >
              <el-option
                v-for="type in types"
                :id="type.value"
                :key="type.value"
                :label="type.label"
                :value="type.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- Discount -->
        <el-col :lg="12">
          <el-form-item
            v-if="selectedType === 'discount'"
            :label="$t('pages.pricebooks.entries.create.discounted_by')"
            prop="calculatedDiscountedBy"
            class="mb-0"
          >
            <th-number-input
              id="calculatedDiscountedBy"
              ref="calculatedDiscountedBy"
              v-model="calculatedDiscountedBy"
              percent
              :precision="2"
              :upper-limit="1.0"
              :locale="$i18n.locale"
              prefix="-"
              clearable
              :placeholder="'-' + $formatNumber(0, { style: 'percent' })"
              @update:modelValue="
                (v) => handleDiscountedByChanged(v, 'calculatedDiscountedBy')
              "
            />
          </el-form-item>

          <!-- Markup -->
          <el-form-item
            v-if="selectedType === 'markup'"
            :label="$t('pages.pricebooks.entries.create.markedup_by')"
            prop="calculatedMarkedupBy"
            class="mb-0"
          >
            <th-number-input
              id="calculatedMarkedupBy"
              ref="calculatedMarkedupBy"
              v-model="calculatedMarkedupBy"
              class="w-full"
              percent
              prefix="+"
              :upper-limit="100"
              :locale="$i18n.locale"
              :placeholder="'+' + $formatNumber(0, { style: 'percent' })"
              @update:modelValue="
                (v) => handleDiscountedByChanged(v, 'calculatedMarkedupBy')
              "
            />
          </el-form-item>

          <!-- Gross Book Price -->
          <el-form-item
            v-if="selectedType === 'gross_price'"
            :label="$t('pages.pricebooks.entries.create.gross_book_price')"
            prop="amount_gross"
            class="mb-0"
          >
            <th-currency-input
              id="amount_gross"
              ref="amount_gross"
              class="w-full"
              :currency="bulk.currency"
              :model-value="bulk.amount_gross"
              @update:modelValue="handleGrossPriceChanged"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div class="flex-grow" />

      <!-- Create -->
      <el-button
        v-permissions="{
          scopes: ['products:pricebooks:create']
        }"
        type="primary"
        @click="submitBulkForm"
        v-text="$t(`common.interactions.buttons.create`)"
      />
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import set from 'just-safe-set'
import ProductsSelect from '@/containers/multi-items-select/products'
import ProductGroupsSelect from '@/containers/multi-items-select/product-groups'
import { getDefaultCurrency } from '@/utils/general'
import { productType, isPurchaseItem } from '@/utils/products'

export default {
  components: {
    ProductsSelect,
    ProductGroupsSelect
  },
  props: {
    pricebookId: {
      type: String,
      required: true
    },
    resources: {
      type: Object,
      required: true
    },
    productsIdArray: {
      type: Array,
      default: () => []
    },
    availableIn: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      bulk: {
        product: null,
        products: null,
        product_groups: null,
        price_book: null,
        name: null,
        currency: getDefaultCurrency(),
        discounted_by: null,
        amount_gross: null,
        amount_net: null,
        rate: null,
        tax: null
      },
      loading: false,
      rules: {
        products: [{ validator: this.validateProducts, trigger: 'blur' }],
        product_groups: [{ validator: this.validateProducts, trigger: 'blur' }],
        calculatedDiscountedBy: [
          { validator: this.validateTypes, trigger: 'blur' }
        ],
        calculatedMarkedupBy: [
          { validator: this.validateTypes, trigger: 'blur' }
        ],
        amount_gross: [{ validator: this.validateTypes, trigger: 'blur' }]
      },
      calculatedDiscountedBy: null,
      calculatedMarkedupBy: null,
      products: [],
      selectedType: 'discount',
      types: [
        {
          value: 'discount',
          label: this.$t('pages.pricebooks.entries.create.discounted_by')
        },
        {
          value: 'markup',
          label: this.$t('pages.pricebooks.entries.create.markedup_by')
        },
        {
          value: 'gross_price',
          label: this.$t('pages.pricebooks.entries.create.gross_book_price')
        },
        {
          value: 'same_amount',
          label: this.$t('pages.pricebooks.entries.create.same_amount')
        }
      ],
      extendFilters: [
        {
          name: 'is_service',
          type: 'select',
          label: this.$t('pages.products.edit.form.service.title'),
          options: [
            {
              label: this.$t('common.interactions.buttons.yes'),
              value: 'true'
            },
            {
              label: this.$t('common.interactions.buttons.no'),
              value: 'false'
            }
          ]
        }
      ]
    }
  },
  computed: {
    extendQuery() {
      const { locations, branch_groups } = this.availableIn
      return {
        sellable: true,
        exclude_system_products: true,
        type: [
          'product',
          'composed_product',
          'voucher',
          'linked',
          'linked_product',
          'variant',
          'variant_product'
        ],
        location: locations || undefined,
        branch_groups: branch_groups || undefined,
        location_strict:
          locations?.length || branch_groups?.length ? true : undefined
      }
    }
  },
  methods: {
    close() {
      this.$thModal.hide('bulk-entry-form-2')
    },
    submitBulkForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.warning.invalid_input.message')
          })
        }
        const entryBulk = this.formatBulkForm(this.bulk)
        await this.create(entryBulk)
      })
    },
    formatBulkForm(form) {
      const { products, product_groups, discounted_by, amount_gross } = form
      return {
        products: products?.map((p) => p.id) ?? null,
        product_groups: product_groups?.map((pg) => pg.id) ?? null,
        price_book: this.pricebookId,
        currency: getDefaultCurrency(),
        discounted_by: this.selectedType === 'same_amount' ? 0 : discounted_by,
        amount_gross
      }
    },
    async create(entry) {
      this.loading = true
      try {
        await th.products().pricebookEntries().create(entry)
        this.$emit('refresh')
        this.close()
      } catch (err) {
        this.$logException(err, {
          message: this.$t('pages.pricebooks.entries.messages.create_error')
        })
      } finally {
        this.loading = false
      }
    },
    validateProducts(rule, value, callback) {
      const { products, product_groups } = this.bulk
      if (!products?.length && !product_groups?.length) {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      }
      callback()
    },
    resetTypes() {
      this.bulk.discounted_by = this.bulk.amount_gross = this.calculatedDiscountedBy = this.calculatedMarkedupBy = null
    },
    validateTypes(rule, value, callback) {
      const { discounted_by, amount_gross } = this.bulk
      if (discounted_by === null && amount_gross === null) {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      }
      callback()
    },
    handleDiscountedByChanged(v, type) {
      if (v === null) return
      if (type === 'calculatedDiscountedBy' && v) {
        this.$refs.calculatedMarkedupBy?.handleClear()
        set(this.bulk, 'discounted_by', v)
      } else if (type === 'calculatedMarkedupBy' && v) {
        this.$refs.calculatedDiscountedBy?.handleClear()
        set(this.bulk, 'discounted_by', -v)
      }
      set(this.bulk, 'amount_gross', null)
    },
    handleGrossPriceChanged(v) {
      if (v === null) return
      this.$refs.calculatedDiscountedBy?.handleClear()
      this.$refs.calculatedMarkedupBy?.handleClear()
      set(this.bulk, 'discounted_by', null)
      set(this.bulk, 'amount_gross', v)
    },
    validate(cb) {
      this.$refs.form.validate((valid) => {
        return cb(valid)
      })
    },
    filterProducts(products) {
      return products.filter(
        (product) =>
          product.type !== productType.VARIANT_PRODUCT &&
          !isPurchaseItem(product) &&
          !this.productsIdArray.includes(product.id)
      )
    }
  }
}
</script>
