import Decimal from 'decimal.js-light'
import get from 'just-safe-get'

// Gross Book Price = (1-Discount) * Original Default Price
const calcGross = ({ discount, defaultPriceGross }) =>
  new Decimal(1).minus(discount).mul(defaultPriceGross)

// Discount = 1 - ( Gross Book Price / Original Default Price )
const calcDiscount = ({ gross, defaultPriceGross }) =>
  new Decimal(1).minus(gross.div(defaultPriceGross))

// Net = (1-VAT) * Gross Book Price
const calcNet = ({ gross, vat }) => new Decimal(1).minus(vat).mul(gross)

// Margin = (Gross Book Price / ((Purchase Price + Cost) * (1+VAT))) -1
const calcMargin = ({ gross, purchasePrice, cost, vat }) =>
  gross
    .div(purchasePrice.add(cost).mul(new Decimal(1).add(vat)))
    .minus(new Decimal(1))

const getMarginValue = ({ purchasePrice, cost, gross, vat }) => {
  const isMarginValid =
    purchasePrice.toNumber() &&
    purchasePrice.add(cost).toNumber() &&
    gross.toNumber()

  return !isMarginValid
    ? new Decimal(0)
    : calcMargin({
        gross,
        cost,
        purchasePrice,
        vat
      })
}

/**
 * calculate price from incoming gross
 * @param {Number} gross
 * @param {Object} prices
 * @param {Number} taxRate
 */
export function onGross(gross, prices, taxRate) {
  const _gross = new Decimal(gross ?? 0)
  const vat = new Decimal(taxRate)

  const defaultPriceObj = get(prices, 'default_prices.0') ?? {}

  const purchasePrice = new Decimal(defaultPriceObj.purchase_price ?? 0)
  const cost = new Decimal(defaultPriceObj.cost ?? 0)
  const defaultPriceGross = new Decimal(defaultPriceObj.amount?.gross ?? 0)

  const net = calcNet({ gross: _gross, vat })
  const margin = getMarginValue({ gross: _gross, vat, purchasePrice, cost })
  let discountedBy = new Decimal(0)

  const grossNumber = _gross.toNumber()
  if (!grossNumber || !defaultPriceGross.toNumber()) {
    const value = !grossNumber ? 1 : 0
    discountedBy = new Decimal(value)
  } else {
    discountedBy = calcDiscount({
      gross: _gross,
      defaultPriceGross: defaultPriceGross
    })
  }

  const resultDiscountedBy = discountedBy.toDecimalPlaces(4)
  const resultMargin = margin.toDecimalPlaces(4)
  const resultNet = net.toDecimalPlaces(2)

  return {
    margin: resultMargin.toNumber(),
    discountedBy: resultDiscountedBy.toNumber(),
    net: resultNet.toNumber(),
    gross: _gross.toNumber()
  }
}

/**
 * calculate price from incoming discount
 * @param {Number} discountedBy
 * @param {Object} prices
 * @param {Number} taxRate
 */
export function onDiscountedBy(discountedBy, prices, taxRate) {
  const _discountedBy = new Decimal(discountedBy ?? 0)
  const vat = new Decimal(taxRate)

  const defaultPriceObj = get(prices, 'default_prices.0') ?? {}

  const purchasePrice = new Decimal(defaultPriceObj.purchase_price ?? 0)
  const defaultPriceGross = new Decimal(defaultPriceObj.amount?.gross ?? 0)
  const cost = new Decimal(defaultPriceObj.cost ?? 0)

  const gross = calcGross({ discount: _discountedBy, defaultPriceGross })
  const net = calcNet({ gross, vat })
  const margin = getMarginValue({ gross, vat, purchasePrice, cost })

  const resultGross = gross.toDecimalPlaces(2)
  const resultMargin = margin.toDecimalPlaces(4)
  const resultNet = net.toDecimalPlaces(2)

  return {
    margin: resultMargin.toNumber(),
    discountedBy: _discountedBy.toNumber(),
    net: resultNet.toNumber(),
    gross: resultGross.toNumber()
  }
}
