<template>
  <th-wrapper
    :title="$t('common.titles.general_info.title')"
    body-class="p-8 pb-3"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :lg="8" :md="12">
          <!-- Price Book Name -->
          <el-form-item
            prop="name"
            :label="$t('pages.pricebooks.all.headers.name')"
          >
            <el-input
              id="name"
              v-model="form.name"
              :placeholder="$t('pages.pricebooks.all.headers.name')"
            />
          </el-form-item>
        </el-col>

        <el-col :lg="8" :md="12">
          <!-- location -->
          <el-form-item
            prop="locations"
            :label="
              $t('pages.products.edit.form.properties.available_in.label')
            "
          >
            <available-in
              id="locations"
              :model-value="{
                locations: form.locations,
                branch_groups: form.branch_groups
              }"
              data-testid="locations"
              :resources="resources"
              :show-items-limit="2"
              @update:modelValue="handleAvailableInInput"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :lg="8" :md="12">
          <!-- Price Book Type -->
          <el-form-item
            prop="type"
            :label="$t('pages.pricebooks.edit.form.type.label')"
          >
            <el-select
              id="type"
              v-model="form.type"
              :placeholder="$t('pages.pricebooks.edit.form.type.label')"
              :disabled="!isNew"
            >
              <el-option
                v-for="item in pricebookTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import AvailableIn from '@/components/available-in'
import safeSet from 'just-safe-set'

export default {
  components: {
    AvailableIn
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            max: 32,
            message: this.$t('common.forms.rules.max_length', { length: 32 })
          }
        ],
        locations: [
          {
            validator: this.validateLocations,
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      },
      pricebookTypes: [
        {
          value: 'general',
          name: this.$t('pages.pricebooks.edit.form.type.general')
        },
        {
          value: 'customer',
          name: this.$t('pages.pricebooks.edit.form.type.customer')
        }
      ]
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },

  methods: {
    validateLocations: (_, value, callback) => {
      if (value === null || Array.isArray(value)) return callback()
      callback(new Error(this.$t('common.forms.rules.field_warnings.required')))
    },

    resetFields() {
      this.$refs.form.resetFields()
    },

    handleAvailableInInput({ locations, branch_groups }) {
      safeSet(this.form, 'locations', locations)
      safeSet(this.form, 'branch_groups', branch_groups)
    },

    validate() {
      return new Promise((resolve, _) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
